<template>
  <div class="c-app" style="background-color:#ffffff">
    <div class="c-wrapper">
      <div class="c-body">
        <main class="c-main">
          <CContainer>
            <transition name="fade">
              <div v-if="loading === true">
                <div class="lds-ripple">
                  <div></div>
                  <div></div>
                </div>
              </div>
              <div v-else-if="loading === false">
                <router-view></router-view>
              </div>
            </transition>
          </CContainer>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import '@/util/loader.css'

export default {
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
  },
  beforeCreate() {
    this.$store.dispatch('getShopLineOA')
    this.$store.dispatch('getLineProfile', process.env.VUE_APP_LIFF_ID_MENU)
  },
  methods: {
    redirect() {
      if (this.shopLineOA.length === 0) {
        this.loading = true
      } else if (this.shopLineOA.length > 0) {
        this.loading = false
      }
    },
  },
  watch: {
    shopLineOA() {
      this.redirect()
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
